import axios from "axios";

export class MainService {
    static BASE_URL = "https://cartaoexpress.rx2.com.br/api";
    static async getCities() {
        try {
            const cities = await axios.get(`${this.BASE_URL}/branch/cities`, {
                headers: {
                    "x-access-token": 'Jd3[6;_>[qH#F"Tm',
                },
            });

            const citiesBeautiful = await Promise.all(
                cities.data.map((city, index) => ({
                    value: city,
                    label: city,
                }))
            );

            return citiesBeautiful;
        } catch (error) {
            console.error(error);
            return [];
        }
    }

    static async getSellers(city, name = "") {
        try {
            let URL = `${this.BASE_URL}/users-by-branch?city=${city}`;

            if (name.length > 0) {
                URL = `${URL}&name=${name}`;
            }
            const sellersResponse = await axios.get(encodeURI(URL), {
                headers: {
                    "x-access-token": 'Jd3[6;_>[qH#F"Tm',
                },
            });

            return sellersResponse.data;
        } catch (error) {
            console.error(error);
            return [];
        }
    }

    static async saveClientInfo(clientName, clientCPF, clientPhone) {
        try {
            const sellersResponse = await axios.post(
                `${this.BASE_URL}/clients`,
                {
                    name: clientName,
                    cpf: clientCPF,
                    phone: clientPhone,
                },
                {
                    headers: {
                        "x-access-token": 'Jd3[6;_>[qH#F"Tm',
                    },
                }
            );

            return sellersResponse.status === 201;
        } catch (error) {
            console.error(error);
            return false;
        }
    }

    static async getTabloidIfExists(branchId) {
        try {
            let URL = `${this.BASE_URL}/branch-tabloid-exists/${branchId}/exists`;

            const getTabloidResponse = await axios.get(encodeURI(URL));

            return getTabloidResponse.data.data.branchTabloid;
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    static getURLTabloid(branchTabloidId) {
        return `${this.BASE_URL}/branch-tabloid-file/${branchTabloidId}/file`;
    }
}
