import React, { useState, useEffect, Component } from "react";
import { Button, Modal } from "react-bootstrap";
import Select, { createFilter } from "react-select";
import LoadingOverlay from "react-loading-overlay";
import { FixedSizeList as List } from "react-window";
import { useHistory } from "react-router-dom";
import "./index.css";
import { MainService } from "../../services/MainService";

const height = 35;

class MenuList extends Component {
    render() {
        const { options, children, maxHeight, getValue } = this.props;
        const [value] = getValue();
        const initialOffset = options.indexOf(value) * height;

        return (
            <List
                height={maxHeight}
                itemCount={children.length}
                itemSize={height}
                initialScrollOffset={initialOffset}
            >
                {({ index, style }) => (
                    <div style={style}>{children[index]}</div>
                )}
            </List>
        );
    }
}

const Home = (props) => {
    const history = useHistory();
    const [loader, setLoader] = useState(true);
    const [showModalCity, setShowModalCity] = useState(false);
    const [city, setCity] = useState("");
    const [cities, setCities] = useState([]);

    const handleClose = () => setShowModalCity(false);
    const handleShow = () => setShowModalCity(true);

    useEffect(() => {
        async function loadCities() {
            // const cities = await axios.get(
            //     "http://192.168.3.7:3050/branch/cities",
            //     {
            //         headers: {
            //             "x-access-token": 'Jd3[6;_>[qH#F"Tm',
            //         },
            //     }
            // );

            // const citiesBeautiful = await Promise.all(
            //     cities.data.map((city, index) => ({
            //         value: city,
            //         label: city,
            //     }))
            // );

            const citiesResponse = await MainService.getCities();

            setLoader(false);
            setCities(citiesResponse);
        }

        loadCities();
    }, []);

    return (
        <>
            <Modal centered show={showModalCity} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Encontrar um vendedor</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Select
                        options={cities}
                        filterOption={createFilter({ ignoreAccents: false })} // this makes all the difference!
                        components={{ MenuList }}
                        placeholder="Escolha uma cidade"
                        onChange={(e) => setCity(e.value)}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        onClick={() => {
                            history.push(`/vendedores?city=${encodeURI(city)}`);
                        }}
                    >
                        Buscar!
                    </Button>
                </Modal.Footer>
            </Modal>
            <LoadingOverlay active={loader} spinner text="Buscando...">
                <div className="containerBackground">
                    <div id="containerText">
                        <h1>Conte</h1>
                        <h1>Sempre</h1>
                        <h1>Comigo!</h1>
                        <p>Nosso time de vendedores está a sua disposição!</p>
                    </div>
                    <footer id="footer">
                        <Button
                            id="findSeller"
                            variant="primary"
                            onClick={() => {
                                handleShow();
                            }}
                        >
                            Encontre um vendedor agora mesmo
                        </Button>
                    </footer>
                </div>
            </LoadingOverlay>
        </>
    );
};

export default Home;
