import React, { useState, useEffect } from "react";
import {
    Button,
    Modal,
    Container,
    Row,
    Col,
    Jumbotron,
    Form,
    ListGroup,
    ListGroupItem,
} from "react-bootstrap";
import InputMask from "react-input-mask";
import LoadingOverlay from "react-loading-overlay";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { MainService } from "../../services/MainService";
import "./index.css";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function Sellers() {
    let query = useQuery();
    const history = useHistory();
    const [loader, setLoader] = useState(true);
    const [showModalClientInfo, setShowModalClientInfo] = useState(false);
    const [clientName, setClientName] = useState("");
    const [clientPhone, setClientPhone] = useState("");
    const [clientCPF, setClientCPF] = useState("");
    const [sellers, setSellers] = useState([]);
    const [branch, setBranch] = useState({});
    const [branches, setBranches] = useState([]);
    const [branchTabloid, setBranchTabloid] = useState(null);

    const handleClose = () => setShowModalClientInfo(false);
    const handleShow = () => setShowModalClientInfo(true);

    useEffect(() => {
        async function load() {
            const branchName = query.get("name");
            if (branchName) {
                const sellersResponse = await MainService.getSellers(
                    query.get("city"),
                    branchName
                );
                setSellers(sellersResponse.users);
                setBranch(sellersResponse.branch[0]);
                setBranches(sellersResponse.branch);
            } else {
                const sellersResponse = await MainService.getSellers(
                    query.get("city")
                );
                setSellers(sellersResponse.users);
                setBranch(sellersResponse.branch[0]);
                setBranches(sellersResponse.branch);
            }

            setLoader(false);
        }

        load();
    }, []);

    useEffect(() => {
        async function load() {
            const branchTabloid = await MainService.getTabloidIfExists(
                branch._id
            );
            setBranchTabloid(branchTabloid);
        }

        load();
    }, [branch]);

    const getURLPhoto = (_id) =>
        `https://rxy-imagens.s3.amazonaws.com/vendedores_fotos/${_id}.jpeg`;

    const openURLTabloid = () => {
        window.open(MainService.getURLTabloid(branchTabloid._id), "__blank");
    };

    return (
        <>
            <Modal centered show={showModalClientInfo} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Personalize sua experiência conosco
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        Para personalizar sua experiência conosco por favor
                        insira os seguintes dados opcionais:
                    </p>
                    <Form>
                        <Form.Group controlId="formName">
                            <Form.Label>Nome</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Seu nome"
                                value={clientName}
                                onChange={(e) => {
                                    const { value } = e.target;
                                    setClientName(value);
                                }}
                            />
                        </Form.Group>
                        <Form.Group controlId="formCPF">
                            <Form.Label>CPF</Form.Label>
                            <InputMask
                                mask="999.999.999-99"
                                placeholder="Seu CPF"
                                className="form-control"
                                value={clientCPF}
                                onChange={(e) => {
                                    const { value } = e.target;
                                    setClientCPF(value);
                                }}
                            />
                        </Form.Group>
                        <Form.Group controlId="formPhone">
                            <Form.Label>Telefone</Form.Label>
                            <InputMask
                                mask="(99) 99999-9999"
                                placeholder="Seu Telefone"
                                className="form-control"
                                value={clientPhone}
                                onChange={(e) => {
                                    const { value } = e.target;
                                    setClientPhone(value);
                                }}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleClose}>
                        Fechar
                    </Button>
                    <Button
                        variant="primary"
                        onClick={() => {
                            if (clientName !== "") {
                                if (clientCPF !== "") {
                                    if (clientPhone !== "") {
                                        MainService.saveClientInfo(
                                            clientName,
                                            clientCPF,
                                            clientPhone
                                        );
                                    }
                                }
                            }
                            handleClose();
                        }}
                    >
                        Visualizar vendedores!
                    </Button>
                </Modal.Footer>
            </Modal>
            <LoadingOverlay active={loader} spinner text="Buscando...">
                <Container className="containerBackground2">
                    <Row>
                        <Col xs="12" md="12">
                            <div className="gazin-logo">
                                <img
                                    src='https://rxycolchoes.s3.amazonaws.com/static/Photos/logo_gazin.png'
                                    alt="Gazin Logo"
                                    width="350"
                                />
                            </div>
                            <div className="welcome-tabloid">
                                <h1>Bem-vindo!</h1>
                                {branchTabloid ? (
                                    <>
                                        <p>
                                            Confira as promoções que preparamos
                                            pra você!
                                        </p>
                                        <p>
                                            <Button
                                                variant="primary"
                                                onClick={() => openURLTabloid()}
                                            >
                                                Ver ofertas
                                            </Button>
                                        </p>
                                    </>
                                ) : (
                                    <>
                                        <p>
                                            Nossos vendedores estão a sua
                                            disposição!
                                        </p>
                                    </>
                                )}
                            </div>
                            {branches.length > 1 ? (
                                <Row>
                                    <Col xs="12" md="12">
                                        <p>Clique no endereço desejado:</p>
                                    </Col>
                                    {branches.map((branchItem, index) => (
                                        <Col
                                            xs="12"
                                            md="3"
                                            key={index}
                                            className="address-list-item"
                                            onClick={() =>
                                                (window.location.href =
                                                    window.location.href +
                                                    encodeURI(
                                                        `&name=${branchItem.name}`
                                                    ))
                                            }
                                        >
                                            <p>{branchItem?.name}</p>
                                            <p>{branchItem?.address}</p>
                                            <p>{`${branchItem?.region}, ${branchItem?.cidade_estado}`}</p>
                                        </Col>
                                    ))}
                                </Row>
                            ) : (
                                <>
                                    <p>{branch?.address}</p>
                                    <p>{`${branch?.region}, ${branch?.cidade_estado}`}</p>
                                    <p>
                                        {sellers.length} vendedores disponíveis
                                    </p>
                                </>
                            )}
                            <h2
                                style={{
                                    marginTop: 30,
                                }}
                            >
                                Vendedores da Filial
                            </h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12" md="12">
                            <ListGroup>
                                {sellers.map((seller, index) => (
                                    <ListGroupItem className="item-seller">
                                        <div className="name-photo">
                                            <div className="image-parent">
                                                <img
                                                    src={getURLPhoto(
                                                        seller._id
                                                    )}
                                                    onError={(e) => {
                                                        e.target.src =
                                                            "https://rxycolchoes.s3.amazonaws.com/static/Photos/logo_gazin.png";
                                                    }}
                                                    alt="Foto vendedor"
                                                />
                                            </div>
                                            <span className="seller-name">
                                                <b>
                                                    {seller.name.toLowerCase()}
                                                </b>
                                            </span>
                                        </div>
                                        <span className="seller-position">
                                            <i>{seller.positionInTheCompany}</i>
                                        </span>
                                        <Button
                                            variant="success"
                                            className="buttons-contact"
                                            onClick={() => {
                                                window.open(
                                                    `https://api.whatsapp.com/send?phone=55${seller.phone
                                                        .replace(
                                                            /[^\w\s]/gi,
                                                            ""
                                                        )
                                                        .replace(
                                                            / /g,
                                                            ""
                                                        )}&text=Ol%C3%A1!%20Preciso%20de%20informa%C3%A7%C3%B5es%20sobre%20produtos%2C%20poderia%20me%20ajudar%3F%20Sei%20que%20sempre%20posso%20saber%20das%20ofertas%20mais%20recentes%20na%20fanpage%20https%3A%2F%2Fwww.facebook.com%2Fgazin`,
                                                    "__blank"
                                                );
                                            }}
                                        >
                                            Fale comigo pelo WhatsApp
                                        </Button>
                                        <Button
                                            variant="primary"
                                            className="buttons-contact"
                                            onClick={() => {
                                                window.open(
                                                    `tel:${seller.phone
                                                        .replace(
                                                            /[^\w\s]/gi,
                                                            ""
                                                        )
                                                        .replace(/ /g, "")}`,
                                                    "__blank"
                                                );
                                            }}
                                        >
                                            Ou ligue para mim!
                                        </Button>
                                    </ListGroupItem>
                                ))}
                            </ListGroup>
                        </Col>
                    </Row>
                </Container>
            </LoadingOverlay>
        </>
    );
}

export default Sellers;
